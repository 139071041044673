<div class="modal-header">
  <h4 class="modal-title" id="modal-createUpdateTaxRate">{{ parentEntity.taxName }} - {{ currentEntity.rate }}%</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <section>
    <ul>
      <li id="createUpdateErrorMessages" class="text-danger field-validation-error" *ngFor="let err of errors">
        {{ err }}
      </li>
    </ul>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">Start Date</label>
      <form class="form-inline">
        <div class="form-group">
          <div class="input-group">
            <input class="form-control" placeholder="yyyy-mm-dd"
                   name="dpStartDate" [(ngModel)]="startDate" ngbDatepicker [markDisabled]="isDisabled" #dcpStartDate="ngbDatepicker">
            <div class="input-group-append bg-default" (click)="dcpStartDate.toggle()" >
              <span class="input-group-text">
                <i class="fa fa-calendar"></i>
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="form-group row">
      <label class="col-sm-3 col-form-label">End Date</label>
      <form class="form-inline">
        <div class="form-group">
          <div class="input-group">
            <input class="form-control" placeholder="yyyy-mm-dd"
                   name="dpEndDate" [(ngModel)]="endDate" ngbDatepicker [markDisabled]="isDisabled" #dcpEndDate="ngbDatepicker">
            <div class="input-group-append bg-default" (click)="dcpEndDate.toggle()" >
              <span class="input-group-text">
                <i class="fa fa-calendar"></i>
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="form-group row">
      <label class="col-sm-3 col-form-label">Rate</label>
      <div class="col-sm-9">
        <input [(ngModel)]="currentEntity.rate" class="form-control"/>
      </div>
    </div>
  </section>
</div>
<div class="modal-footer">
  <span class="bpAuditInfo" *ngIf="!isNew">{{currentEntity.updatedBy}} on {{ currentEntity.updatedDate | date }} </span>
  <button type="button" class="btn btn-primary btn-sm" (click)="this.save()">Save</button>
  <button type="button" class="btn btn-default btn-sm" (click)="modal.close()">Close</button>
</div>
