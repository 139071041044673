import { Component, OnInit } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WizardBudgetPlannerService } from "../../../WizardBudgetPlannerService";
import { TaxSystem, TaxType } from "@expertasolutions/budgetplanner-ui-common";
import {WizardDataSingleton} from "../../../WizardDataSingleton";

@Component({
  selector: 'taxsystems',
  templateUrl: './taxSystems.component.html'
})
export class TaxSystemsComponent implements OnInit {
  public currentEntity: TaxSystem = null;
  public currentTaxType: TaxType = null;
  public cmnSingleton: WizardDataSingleton = null;

  constructor(private bpService:WizardBudgetPlannerService, private modalService: NgbModal) {

  }

  ngOnInit() {
    this.cmnSingleton = WizardDataSingleton.getInstance(this.bpService);
  }

  public viewEditTaxSystem(model:TaxSystem, content:any) {
    if(model == null) {
      this.currentEntity = {} as TaxSystem;
      this.modalService.open(content, {ariaLabelledBy: 'modal-createUpdateTaxSystem'}).result.then(() => {
        this.cmnSingleton.reloadTaxSystems();
      });
    } else {
      this.currentEntity = model;
      this.modalService.open(content, {ariaLabelledBy: 'modal-createUpdateTaxSystem'}).result.then(() => {
        this.cmnSingleton.reloadTaxSystems();
      });
    }
  }

  public viewDeleteTaxSystem(model:TaxSystem, content:any) {
    this.currentEntity = model;
    this.modalService.open(content, {ariaLabelledBy: 'modal-deleteTaxSystem'}).result.then(() => {
      this.cmnSingleton.reloadTaxSystems();
    });
  }

  public viewEditTaxType(model:TaxType, content:any) {
    if(model == null) {
      this.currentTaxType = {} as TaxType;
      this.modalService.open(content, {ariaLabelledBy: 'modal-createUpdateTaxType'}).result
        .then(() => {
          this.cmnSingleton.reloadTaxTypes();
        });
    } else {
      this.bpService.reloadData("taxtype", model.identifier)
        .then(result => {
          this.currentTaxType = result.data;
          this.modalService.open(content, {ariaLabelledBy: 'modal-createUpdateTaxType'}).result
            .then(() => {
              this.cmnSingleton.reloadTaxTypes();
            });
        })
    }
  }

  public viewDeleteTaxType(model:TaxType, content:any) {
    this.currentTaxType = model;
    this.modalService.open(content, {ariaLabelledBy: 'modal-deleteTaxType'}).result
      .then(() => {
        this.cmnSingleton.reloadTaxTypes();
      });
  }
}
