import { Component, Input, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { WizardBudgetPlannerService } from "../../../WizardBudgetPlannerService";
import { CompanyInfo } from "@expertasolutions/budgetplanner-ui-common";
import { MatStepper } from "@angular/material/stepper";
import { WizardDataSingleton} from "../../../WizardDataSingleton";
import { NotificationService} from "../../../notification.service";
import { MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'register-newcompany',
  templateUrl: './register.component.html',
  styleUrls: [
    './register.component.scss',
    '../../../../assets/landing/css/style.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit {
  @ViewChild(MatStepper) wizardCpm: MatStepper;
  @Input() modal:any
  public selectedPlanIdentifier: string;

  public cmnSingleton: WizardDataSingleton = null;
  public currentCompany: any = {} as CompanyInfo;
  public aimAgree: boolean = false;
  public errors: any[] = [];

  constructor(public notificationService: NotificationService
              , private bpService: WizardBudgetPlannerService
              , private _snackBar: MatSnackBar
  ) { }

  async ngOnInit() {

    this.bpService.intentional = true;
    await this.bpService.init();
    this.bpService.changeCurrentCompany().then(() => {
      this.cmnSingleton = WizardDataSingleton.getInstance(this.bpService, true);
      this.bpService.getCurrentCompany().then(result=> {
        this.currentCompany = result;
      });
    });

  }

  public planIdentifierChanged(evt) {
    this.selectedPlanIdentifier = evt;
  }

  public abort() {
    this.bpService.abort();
  }

  public saveCompanyInfo() {
    this.bpService.post('/api/CompanyInfo/CreateNewCompany', this.currentCompany)
      .then((result)=> {
        if(result.success) {
          this.currentCompany = result.data;
          this.cmnSingleton.clearCommonEntities();
          this.cmnSingleton.reloadCommonEntities();
          this.wizardCpm.next();
        } else {
          this.errors = result.errors;
          console.log(this.errors);
          let errormsg = '';
          this.errors.forEach((err)=> {
            errormsg += err + '\n';
          });
          this.notificationService.error(errormsg);
        }
      });
  }

  public previousStep() {
    this.wizardCpm.previous();
  }

  public saveTaxSystem() {
    this.wizardCpm.next();
  }

  public saveEmployees() {
    this.wizardCpm.next();
  }

  public saveCustomers() {
    this.wizardCpm.next();
  }

  public saveProjects() {
    this.wizardCpm.next();
  }

  public savePlanTier() {
    this.bpService.post(`/api/companyInfo/AssignSubscriptionPlan/${this.selectedPlanIdentifier}`, this.currentCompany)
      .then(result => {
        if(result.success) {
          this.currentCompany = result.data;
          this.wizardCpm.next();
        }
      });
  }

  public async closeWizard() {
    this.bpService.post(`/api/CompanyInfo/CompleteRegistration`, this.currentCompany)
      .then((result) => {
        if(result.success) {
          this.bpService.transfertToAdminPortal(result.data);
        }
      });
  }
}
