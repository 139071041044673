<div class="row m-t-30">
  <div class="col-md-12 p-r-0" style="text-align: center;">
    <a href="javascript:void(0);" (click)="viewEdit(null, createUpdateModal)" class="btn btn-sm btn-success text-uppercase btn-block f-14">Add employee</a>
  </div>
</div>

<div class="col-xl-12 col-md-12">
  <div class="row" style="align-items: center;">
    <div class="col-xl-4 col-md-4" *ngFor="let empl of this.sglCommonData.employees" >
      <div class="card">
          <div class="text-center m-b-15">
            <img class="img-fluid rounded-circle" src="assets/images/user/avatar-2.jpg" alt="dashboard-user">
            <h5 class="mt-3">{{ empl.fullName }}</h5>
            <span class="d-block">{{ empl.email }}</span>
          </div>
          <div style="text-align: center;">
            <a href="javascript:void(0);" (click)="viewDelete(empl, deleteModal)" class="btn btn-sm btn-danger text-uppercase btn-block f-14">Remove</a>
          </div>
      </div>
    </div>
  </div>
</div>

<!-- Edit Modal -->
<ng-template #createUpdateModal let-modal>
  <createUpdate-employee
    [currentEntity]="this.currentEntity"
    [modal]="modal"
    [showStats]="false">
  </createUpdate-employee>
</ng-template>

<!-- Delete Modal -->
<ng-template #deleteModal let-modal>
  <delete-employee
    [currentEntity]="this.currentEntity"
    [modal]="modal">
  </delete-employee>
</ng-template>
