<div class="modal-header">
  <h4 class="modal-title" id="modal-createUpdateTaxSystem">{{ currentEntity.name }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <section>

    <ul>
      <li id="createUpdateErrorMessages" class="text-danger field-validation-error" *ngFor="let err of errors">
        {{ err }}
      </li>
    </ul>

    <div class="form-group row">
      <label class="col-sm-3 col-form-label">Name</label>
      <div class="col-sm-9">
        <input [(ngModel)]="currentEntity.name" class="form-control"/>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-3 col-form-label">Gst Rate</label>
      <div class="col-sm-9">
        <select [(ngModel)]="currentEntity.gstTaxIdentifier" class="form-select">
          <option value="">Please select one</option>
          <option *ngFor="let elm of this.cmnSingleton.taxTypes" value="{{ elm.identifier }}">{{ elm.taxName }} ({{ elm.currentRate.rate }}%)</option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-3 col-form-label">Pst Rate</label>
      <div class="col-sm-9">
        <select [(ngModel)]="currentEntity.pstTaxIdentifier" class="form-select">
          <option value="">Please select one</option>
          <option *ngFor="let elm of this.cmnSingleton.taxTypes" value="{{ elm.identifier }}">{{ elm.taxName }} ({{ elm.currentRate.rate }}%)</option>
        </select>
      </div>
    </div>
  </section>
</div>
<div class="modal-footer">
  <span class="bpAuditInfo" *ngIf="!isNew">{{currentEntity.updatedBy}} on {{ currentEntity.updatedDate | date }} </span>
  <button type="button" class="btn btn-primary btn-sm" (click)="this.save()">Save</button>
  <button type="button" class="btn btn-default btn-sm" (click)="this.modal.dismiss()">Close</button>
</div>
