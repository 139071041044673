    <app-card cardTitle="Tax Types">
      <table class="table table-hover table-condensed" aria-labelledby="tableLabel">
        <thead>
        <tr>
          <th>
            <a href="javascript:void(0);"(click)="viewEditTaxType(null, createUpdateModalTaxType)">
              <i class='feather icon-plus fa-lg bpAddNew'></i>
            </a>
          </th>
          <th>Name</th>
          <th>Number</th>
          <th class="bpRight">Current Rate</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let tx of this.cmnSingleton.taxTypes">
          <td class="bpActionButton">
            <action-button [model]="tx"
                           (viewEdit)="viewEditTaxType(tx, createUpdateModalTaxType)"
                           (viewDelete)="viewDeleteTaxType(tx, deleteModalTaxType)"
                           [hasDocument]="false">
            </action-button>
          </td>
          <td>{{ tx.taxName }}</td>
          <td>{{ tx.taxNumber }}</td>
          <td class="bpRight">{{ tx.currentRate.rate.toFixed(3) }}%</td>
        </tr>
        </tbody>
      </table>
    </app-card>

    <app-card cardTitle="Tax Systems">
      <table class="table table-hover table-condensed" aria-labelledby="tableLabel">
        <thead>
        <tr>
          <th>
            <a href="javascript:void(0)" (click)="viewEditTaxSystem(null, createUpdateModalTaxSystem)">
              <i class='feather icon-plus fa-lg bpAddNew'></i>
            </a>
          </th>
          <th>Name</th>
          <th>Gst</th>
          <th class="bpRight">Gst rate %</th>
          <th>Pst</th>
          <th class="bpRight">Pst rate %</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let tx of this.cmnSingleton.taxSystems">
          <td class="bpActionButton">
            <action-button [model]="tx"
                           (viewEdit)="viewEditTaxSystem(tx, createUpdateModalTaxSystem)"
                           (viewDelete)="viewDeleteTaxSystem(tx, deleteModalTaxSystem)"
                           [hasDocument]="false">
            </action-button>
          </td>
          <td>{{ tx.name }}</td>
          <td>{{ tx.gstTax?.taxName }}</td>
          <td class="bpRight">{{ tx.gstTax?.currentRate.rate.toFixed(3) }}%</td>
          <td>{{ tx.pstTax?.taxName }}</td>
          <td class="bpRight">{{ tx.pstTax?.currentRate.rate.toFixed(3) }}%</td>
        </tr>
        </tbody>
      </table>
    </app-card>

<!-- Edit Modal -->
<ng-template #createUpdateModalTaxSystem let-modal>
  <createUpdate-taxsystem
    [currentEntity]="this.currentEntity"
    [modal]="modal">
  </createUpdate-taxsystem>
</ng-template>

<!-- Delete Modal -->
<ng-template #deleteModalTaxSystem let-modal>
  <delete-taxsystem
    [currentEntity]="this.currentEntity"
    [modal]="modal">
  </delete-taxsystem>
</ng-template>

<!-- Edit Modal -->
<ng-template #createUpdateModalTaxType let-modal>
  <createUpdate-taxtype
    [currentEntity]="this.currentTaxType"
    [modal]="modal">
  </createUpdate-taxtype>
</ng-template>

<!-- Delete Modal -->
<ng-template #deleteModalTaxType let-modal>
  <delete-taxtype
    [currentEntity]="this.currentTaxType"
    [modal]="modal">
  </delete-taxtype>
</ng-template>
