<div class="modal-header">
  <h4 class="modal-title" id="modal-createUpdate-employee">{{ currentEntity.fullName }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <section *ngIf="this.showStats">
    <div class="row">
      <div class="col-md-4 col-xl-4">
        <app-card cardClass="theme-bg bitcoin-wallet" [hidHeader]="true" [options]="false">
          <h6 class="text-white mb-2">Worked hours</h6>
          <h5 class="text-white mb-2 f-w-300">This month: {{ kpiStats.hoursWorkThisMonth.toFixed(2) }} hours</h5>
          <span class="text-white d-block">Last month: {{ kpiStats.hoursWorkLastMonth.toFixed(2) }} hours</span>
          <i class="fas fa-project-diagram f-60 text-white"></i>
        </app-card>
      </div>
      <div class="col-md-4 col-xl-4">
        <app-card cardClass="theme-bg2 bitcoin-wallet" [hidHeader]="true" [options]="false">
          <h6 class="text-white mb-2">Monthly salary</h6>
          <h5 class="text-white mb-2 f-w-300">This month: {{ kpiStats.salaryThisMonth | currency }}</h5>
          <span class="text-white d-block">Last month: {{ kpiStats.salaryLastMonth | currency }}</span>
          <i class="fas fa-dollar-sign f-60 text-white"></i>
        </app-card>
      </div>
      <div class="col-md-4 col-xl-4">
        <app-card cardClass="theme-bg bitcoin-wallet" [hidHeader]="true" [options]="false">
          <h6 class="text-white mb-2">Yearly salary</h6>
          <h5 class="text-white mb-2 f-w-300">This year: {{ kpiStats.salaryThisYear | currency }}</h5>
          <span class="text-white d-block">Last year: {{ kpiStats.salaryLastYear | currency }}</span>
          <i class="fas fa-dollar-sign f-60 text-white"></i>
        </app-card>
      </div>
    </div>
  </section>

  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink>Employee Informations</a>
      <ng-template ngbNavContent>
        <div>
          <ul>
            <li id="createUpdateErrorMessages" class="text-danger field-validation-error" *ngFor="let err of errors">
              {{ err }}
            </li>
          </ul>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label class="form-group">Firstname</label>
                <input [(ngModel)]="currentEntity.firstName" class="form-control"/>
              </div>
              <div class="form-group col-md-6">
                <label class="form-group">LastName</label>
                <input [(ngModel)]="currentEntity.lastName" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="form-group col-md-6">
                <label class="form-group">Email</label>
                <input [(ngModel)]="currentEntity.email" class="form-control"/>
              </div>
              <div class="form-group col-md-6">
                <label class="form-group">Timezone</label>
                <select [(ngModel)]="currentEntity.baseUtcOffset" class="form-select">
                  <option value="">Please select one</option>
                  <option *ngFor="let elm of this.cmnSingleton.timezonesList" value="{{ elm.value }}">{{ elm.text }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2" *ngIf="false">
      <a ngbNavLink>Timesheet</a>
      <ng-template ngbNavContent>
        <div></div>
      </ng-template>
    </li>
    <li [ngbNavItem]="3" *ngIf="false">
      <a ngbNavLink>Salary</a>
      <ng-template ngbNavLink>
        <div></div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
<div class="modal-footer">
  <span class="bpAuditInfo" *ngIf="!this.isNew">{{currentEntity.updatedBy}} on {{ currentEntity.updatedDate | date }} </span>
  <button type="button" class="btn btn-primary btn-sm" (click)="this.save()">Save</button>
  <button type="button" class="btn btn-default btn-sm" (click)="this.modal.dismiss()">Close</button>
</div>
