<div class="modal-header">
  <h4 class="modal-title" id="modal-createUpdateTaxType">{{ currentEntity.taxName }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <section>
    <ul>
      <li id="createUpdateErrorMessages" class="text-danger field-validation-error" *ngFor="let err of errors">
        {{ err }}
      </li>
    </ul>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">Name</label>
      <div class="col-sm-9">
        <input [(ngModel)]="currentEntity.taxName" class="form-control"/>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">Number</label>
      <div class="col-sm-9">
        <input [(ngModel)]="currentEntity.taxNumber" class="form-control"/>
      </div>
    </div>
  </section>
  <section *ngIf="!isNew">
    <table class="table table-hover table-condensed" aria-labelledby="tableLabel">
      <thead>
      <tr>
        <th>
          <a href="javascript:void(0);" (click)="viewEdit(null, createUpdateTaxRateModal)">
            <i class='feather icon-plus fa-lg bpAddNew'></i>
          </a>
        </th>
        <th>Start Date</th>
        <th>End Date</th>
        <th class="bpRight">Rate %</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let tx of this.currentEntity.rates">
        <td class="bpActionButton">
          <action-button [model]="tx"
                         (viewEdit)="viewEdit(tx, createUpdateTaxRateModal)"
                         (viewDelete)="viewDelete(tx, deleteTaxRateModal)"
                         [hasDocument]="false">
          </action-button>
        </td>
        <td>{{ tx.startDate | date }}</td>
        <td>{{ tx.endDate | date }}</td>
        <td class="bpRight">{{ tx.rate.toFixed(3) }}%</td>
      </tr>
      </tbody>
    </table>
  </section>
</div>
<div class="modal-footer">
  <span class="bpAuditInfo" *ngIf="!isNew">{{currentEntity.updatedBy}} on {{ currentEntity.updatedDate | date }} </span>
  <button type="button" class="btn btn-primary btn-sm" (click)="this.save()">Save</button>
  <button type="button" class="btn btn-default btn-sm" (click)="modal.close()">Close</button>
</div>

<!-- Edit Modal -->
<ng-template #createUpdateTaxRateModal let-modal>
  <createUpdate-taxrate
    [parentEntity]="this.currentEntity"
    [currentEntity]="this.currentRate"
    [modal]="modal">
  </createUpdate-taxrate>
</ng-template>

<!-- Delete Modal -->
<ng-template #deleteTaxRateModal let-modal>
  <delete-taxrate
    [currentEntity]="this.currentRate"
    [modal]="modal">
  </delete-taxrate>
</ng-template>
