import { Component, Input } from '@angular/core';
import { WizardBudgetPlannerService } from "../../../WizardBudgetPlannerService";
import { TaxSystem } from "@expertasolutions/budgetplanner-ui-common";

@Component({
  selector: 'delete-taxsystem',
  templateUrl: './deleteTaxSystemModal.component.html'
})
export class DeleteTaxSystemModalComponent {
  @Input() currentEntity?: TaxSystem
  @Input() modal:any

  public errors = [];

  constructor(private bpService:WizardBudgetPlannerService) {

  }

  public deleteEntity() {
    this.bpService.deleteEntity('/api/taxsystem/delete', this, () => {
      this.modal.close();
    });
  }
}
