import { Component, Input, OnInit } from '@angular/core';
import { WizardBudgetPlannerService } from "../../../WizardBudgetPlannerService";
import { TaxType, TaxRate } from "@expertasolutions/budgetplanner-ui-common";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import {WizardDataSingleton} from "../../../WizardDataSingleton";

@Component({
  selector: 'createUpdate-taxrate',
  templateUrl: './createUpdateTaxRateModal.component.html'
})
export class CreateUpdateTaxRateComponent implements OnInit {
  @Input() parentEntity?: TaxType
  @Input() currentEntity?: TaxRate
  @Input() modal:any

  public errors = [];
  public cmnSingleton: WizardDataSingleton = null;

  public startDate: any;
  public endDate: any;

  constructor(public parserFormatter: NgbDateParserFormatter, private bpService:WizardBudgetPlannerService) {

  }

  ngOnInit() {
    this.cmnSingleton = WizardDataSingleton.getInstance(this.bpService);

    if(!this.isNew) {
      if(this.currentEntity.startDate != null) {
        this.startDate = this.parserFormatter.parse(this.currentEntity.startDate.toString());
      }

      if(this.currentEntity.endDate != null) {
        this.endDate = this.parserFormatter.parse(this.currentEntity.endDate.toString());
      }
    }
  }

  isDisabled(date: NgbDateStruct, current: {month: number}) {
    return date.month !== current.month;
  }

  public get isNew() {
    return this.currentEntity.identifier == null;
  }

  public save() {
    let sD = this.startDate;
    let eD = this.endDate;

    this.currentEntity.startDate = new Date(this.parserFormatter.format(sD));
    this.currentEntity.endDate = new Date(this.parserFormatter.format(eD));

    this.bpService.submitContent("/api/taxrate/", this, ()=> {
      this.modal.close();
    });
  }
}
