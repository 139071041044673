<section>
  <form #companyFormGroup="ngForm">
    <div>

      <div class="form-group row">
        <label for="neq" class="col-sm-3 col-form-label" style="text-align: right;">Organization Type</label>
        <div class="col-sm-6">
          <select name="orgTypeIdentifier" id="orgTypeIdentifier"
                  #orgTypeIdentifier="ngModel"
                  [(ngModel)]="currentEntity.orgTypeIdentifier"
                  (ngModelChange)="onOrgTypeChange()"
                  class="form-select" required>
            <option value="">Choose your Organization Type</option>
            <option *ngFor="let orgType of this.cmnSingleton.organizationTypes"
                    [ngValue]="orgType.identifier">{{ orgType.type }}</option>
          </select>
        </div>
        <div class="col-sm-3"
             *ngIf="orgTypeIdentifier?.invalid && (orgTypeIdentifier?.dirty || orgTypeIdentifier?.touched)">
          <label *ngIf="orgTypeIdentifier.errors?.['required']" class="bpvalidation-error">
            Organization Type is required.
          </label>
        </div>
      </div>



      <div class="form-group row">
        <label class="col-sm-3 col-form-label" style="text-align: right;" for="companyName">Company Name</label>
        <div class="col-sm-6">
          <input  type="text"
                  name="companyName"
                  id="companyName"
                  [(ngModel)]="currentEntity.name"
                  #companyName="ngModel"
                  required minlength="3" maxlength="30"
                  companyNameValidator
                 class="form-control" />
        </div>
        <div class="col-sm-3"
             *ngIf="companyName?.invalid && (companyName?.dirty || companyName?.touched)">
          <label *ngIf="companyName.errors?.['required']" class="bpvalidation-error">
            Company Name is required.
          </label>
          <label *ngIf="companyName.errors?.['minlength']" class="bpvalidation-error">
            Company Name must be at least 3 characters long.
          </label>
          <label *ngIf="companyName.errors?.['companyNameValidator']" class="bpvalidation-error">
            Company name already exists
          </label>
        </div>
      </div>

      <div class="form-group row">
        <label for="shortName" class="col-sm-3 col-form-label" style="text-align: right;">Short Name</label>
        <div class="col-sm-6">
          <input type="text" name="shortName" id="shortName" #shortName="ngModel"
                 required
                 minlength="4"
                 maxlength="20"
                 companyShortNameValidator
                 [(ngModel)]="currentEntity.shortName"
                 class="form-control"/>
        </div>
        <div class="col-sm-3"
             *ngIf="shortName?.invalid && (shortName?.dirty || shortName?.touched)">
          <label *ngIf="shortName.errors?.['required']" class="bpvalidation-error">
            Short Name is required.
          </label>
          <label *ngIf="shortName.errors?.['minlength']" class="bpvalidation-error">
            Short Name must be at least 4 characters long.
          </label>
          <label *ngIf="shortName.errors?.['companyShortNameValidator']" class="bpvalidation-error">
            Short Name already used
          </label>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label" style="text-align: right;">Country</label>
        <div class="col-sm-6">
          <select name="countryIdentifier" id="countryIdentifier" #countryIdentifier="ngModel"
                  [(ngModel)]="currentEntity.countryIdentifier"
                  (ngModelChange)="onCountryChange()"
                  class="form-select" required>
            <option value="" [selected]="true">Choose your country</option>
            <option *ngFor="let country of this.displaybaleCountries" [ngValue]="country.identifier">{{ country.name }}</option>
          </select>
        </div>
        <div class="col-sm-3"
             *ngIf="countryIdentifier?.invalid && (countryIdentifier?.dirty || countryIdentifier?.touched)">
          <label *ngIf="countryIdentifier.errors?.['required']" class="bpvalidation-error">
            Country is required.
          </label>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label" style="text-align: right;">State</label>
        <div class="col-sm-6">
          <select name="stateIdentifier" id="stateIdentifier" #stateIdentifier="ngModel"
                  [(ngModel)]="currentEntity.stateIdentifier" [ngModelOptions]="{standalone: true}" class="form-select" required>
            <option value="">Choose your state</option>
            <option *ngFor="let state of this.displayableStates" [ngValue]="state.identifier">{{ state.name }}</option>
          </select>
        </div>
        <div class="col-sm-3"
             *ngIf="stateIdentifier?.invalid && (stateIdentifier?.dirty || stateIdentifier?.touched)">
          <label *ngIf="stateIdentifier.errors?.['required']" class="bpvalidation-error">
            State is required.
          </label>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label" style="text-align: right;">City</label>
        <div class="col-sm-6">
          <input type="text" name="city" id="city" #city="ngModel"
                 [(ngModel)]="currentEntity.city" class="form-control" required minlength="8" maxlength="50"/>
        </div>
        <div class="col-sm-3"
             *ngIf="city?.invalid && (city?.dirty || city?.touched)">
          <label *ngIf="city.errors?.['required']" class="bpvalidation-error">
            City is required.
          </label>
          <label *ngIf="city.errors?.['minlength']" class="bpvalidation-error">
            City must be at least 8 characters long.
          </label>
        </div>
      </div>

      <div class="form-group row">
        <label for="address" class="col-sm-3 col-form-label" style="text-align: right;">Address</label>
        <div class="col-sm-6">
          <input type="text" name="address" id="address" #address="ngModel" required minlength="8" maxlength="250"
            [(ngModel)]="currentEntity.address" class="form-control"/>
        </div>
        <div class="col-sm-3"
             *ngIf="address?.invalid && (address?.dirty || address?.touched)">
          <label *ngIf="address.errors?.['required']" class="bpvalidation-error">
            Address is required.
          </label>
          <label *ngIf="address.errors?.['minlength']" class="bpvalidation-error">
            Address must be at least 8 characters long.
          </label>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label" style="text-align: right;">Zip Code</label>
        <div class="col-sm-6">
          <input type="text" name="zipCode" id="zipCode" #zipCode="ngModel"
                 [(ngModel)]="currentEntity.zipCode" class="form-control" required maxlength="10"/>
        </div>
        <div class="col-sm-3"
             *ngIf="zipCode?.invalid && (zipCode?.dirty || zipCode?.touched)">
          <label *ngIf="zipCode.errors?.['required']" class="bpvalidation-error">
            Zip Code is required.
          </label>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label" style="text-align: right;">Phone #</label>
        <div class="col-sm-6">
          <input type="text" name="phone" id="phone" #phone="ngModel"
            [(ngModel)]="currentEntity.phone" class="form-control" required maxlength="15"/>
        </div>
        <div class="col-sm-3"
             *ngIf="phone?.invalid && (phone?.dirty || phone?.touched)">
          <label *ngIf="phone.errors?.['required']" class="bpvalidation-error">
            Phone is required.
          </label>
        </div>
      </div>

      <div class="form-group row">
        <label for="neq" class="col-sm-3 col-form-label" style="text-align: right;">NEQ</label>
        <div class="col-sm-6">
          <input type="text" name="neq" id="neq" #neq="ngModel" required minlength="8" maxlength="20"
                 [(ngModel)]="currentEntity.neq" class="form-control"/>
        </div>
        <div class="col-sm-3"
             *ngIf="neq?.invalid && (neq?.dirty || neq?.touched)">
          <label *ngIf="neq.errors?.['required']" class="bpvalidation-error">
            NEQ is required.
          </label>
          <label *ngIf="neq.errors?.['minlength']" class="bpvalidation-error">
            NEQ must be at least 8 characters long.
          </label>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label" style="text-align: right;">Fiscal Start Date</label>
        <div class="col-sm-4">
          <select name="fyStartMonth" id="fyStartMonth" #fyStartMonth="ngModel" required
                  [(ngModel)]="currentEntity.fyStartMonth" class="form-select" [disabled]="this.disableCustomFiscalPeriod" required>
            <option value="">Fiscal Start Month</option>
            <option *ngFor="let month of this.cmnSingleton.monthsList" [ngValue]="month.id">{{ month.name }}</option>
          </select>
        </div>
        <div class="col-sm-2">
          <select name="fyStartDay" id="fyStartDay" #fyStartDay="ngModel" required
                  [(ngModel)]="currentEntity.fyStartDay" class="form-select" [disabled]="this.disableCustomFiscalPeriod" required>
            <option value="" selected>Fiscal Start Day</option>
            <option *ngFor="let dayNumber of this.getDaysNumberFromMonth;" [ngValue]="dayNumber">{{ dayNumber }}</option>
          </select>
        </div>
        <div class="col-sm-3"
             *ngIf="fyStartDay?.invalid && (fyStartDay?.dirty || fyStartDay?.touched) || fyStartMonth?.invalid && (fyStartMonth?.dirty || fyStartMonth?.touched)">
          <label *ngIf="fyStartMonth.errors?.['required']" class="bpvalidation-error">
            Start Month is required.
          </label>
          <label *ngIf="fyStartDay.errors?.['required']" class="bpvalidation-error">
            Start day is required.
          </label>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label" style="text-align: right;">WebSite</label>
        <div class="col-sm-6">
          <input [(ngModel)]="currentEntity.webSite" [ngModelOptions]="{standalone: true}" class="form-control" maxlength="30"/>
        </div>
        <div class="col-sm-3">

        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label" style="text-align: right;">Timezone</label>
        <div class="col-sm-6">
          <select [(ngModel)]="currentEntity.timezone" class="form-select">
            <option value="">Please select one</option>
            <option *ngFor="let elm of this.cmnSingleton.timezonesList" value="{{ elm.value }}">{{ elm.text }}</option>
          </select>
        </div>
        <div class="col-sm-4">

        </div>
      </div>

    </div>

    <div class="col-sm-12 centered-content">
      <div class="btn-group mt-10">
        <button type="button" class="btn btn-danger btn-sm" (click)="abort()">Cancel</button>
        <button type="button" class="btn btn-primary btn-sm" [disabled]="companyFormGroup.invalid" (click)="submit()">Continue</button>
      </div>
    </div>
  </form>
</section>

