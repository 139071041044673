<div class="modal-header">
  <h4 class="modal-title" id="modal-delete-employee">{{ currentEntity.fullName }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <section>
    <div class="form-group">
      Are you sure to delete this entity ?
    </div>
    <ul>
      <li id="deleteErrorMessages" class="text-danger field-validation-error" *ngFor="let err of errors">
        {{ err }}
      </li>
    </ul>
  </section>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger btn-sm" (click)="this.deleteEntity()">Yes</button>
  <button type="button" class="btn btn-default btn-sm" (click)="this.modal.dismiss()">No</button>
</div>
